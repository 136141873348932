import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { AnalyticsState, analyticsState } from '@/analytics/analyticsState';
import { ItemsInfoState, itemsInfoState } from '../listings/items/items.state';
import { ListingsState, listingsState } from '../listings/v2/listings.state';
import { LotsState, lotsState } from '../lots/lots.state';
import { UserState, userState } from '../users/userState';

export const useGhostStore = create<
  UserState & LotsState & ListingsState & ItemsInfoState & AnalyticsState
>()(
  devtools((set) => ({
    ...userState(set),
    ...lotsState(set),
    ...listingsState(set),
    ...itemsInfoState(set),
    ...analyticsState(set),
  })),
);
