import ListingViewModel from '@/legacy/listings/ListingViewModel';
import { LotInfoViewModel } from './models/lot-info.model';
import { ImageProcess } from './models/lot.image-processing.model';
import { LotViewModel } from './models/lot.model';

export interface LotsState {
  lot?: LotInfoViewModel;
  setLot: (lot: LotInfoViewModel | undefined) => void;
  lotLoading: boolean;
  setLotLoading: (state: boolean) => void;
  listings?: ListingViewModel[];
  listingsCount?: number;
  setListings: (listings: ListingViewModel[] | undefined, listingsCount: number) => void;
  listingsLoading: boolean;
  setListingsLoading: (state: boolean) => void;
  lots?: LotViewModel[];
  lotsCount?: number;
  setLots: (lots: LotViewModel[], lotsCount: number) => void;
  imageProcesses: ImageProcess[];
  setImageProcesses: (imageProcesses: ImageProcess[]) => void;
}

export const lotsState = (set: any): LotsState => ({
  lot: undefined,
  setLot: (lot) => set(() => ({ lot })),
  lotLoading: false,
  setLotLoading: (lotLoading) => set(() => ({ lotLoading })),
  listings: undefined,
  listingsCount: undefined,
  setListings: (listings, listingsCount) => set(() => ({ listings, listingsCount })),
  listingsLoading: false,
  setListingsLoading: (listingsLoading) => set(() => ({ listingsLoading })),
  lots: undefined,
  lotsCount: undefined,
  setLots: (lots, lotsCount) => set(() => ({ lots, lotsCount })),
  imageProcesses: [],
  setImageProcesses: (imageProcesses) => set(() => ({ imageProcesses })),
});
