import ListingViewModel from '@/legacy/listings/ListingViewModel';

export interface ListingsState {
  listing?: ListingViewModel;
  setListing: (lot: ListingViewModel | undefined) => void;
  listingLoading: boolean;
  setListingLoading: (state: boolean) => void;
}

export const listingsState = (set: any): ListingsState => ({
  listing: undefined,
  setListing: (listing) => set(() => ({ listing })),
  listingLoading: false,
  setListingLoading: (listingLoading) => set(() => ({ listingLoading })),
});
