import { ItemsInfo } from './model/item.model';

export interface ItemsInfoState {
  itemsInfo?: ItemsInfo;
  setItemsInfo: (itemsInfo: ItemsInfo | undefined) => void;
  itemsInfoLoading: boolean;
  setItemsInfoLoading: (state: boolean) => void;
}

export const itemsInfoState = (set: any): ItemsInfoState => ({
  itemsInfo: undefined,
  setItemsInfo: (itemsInfo) => set(() => ({ itemsInfo })),
  itemsInfoLoading: false,
  setItemsInfoLoading: (itemsInfoLoading) => set(() => ({ itemsInfoLoading })),
});
