import { UserSummary } from './models/userSummary.model';

export interface UserState {
  user: UserSummary | null;
  isUserCheckComplete: boolean;
  setUser: (user: UserSummary | null) => void;
  setUserCheckComplete: (isUserCheckComplete: boolean) => void;
}

export const userState = (set: any): UserState => ({
  user: null,
  isUserCheckComplete: false,
  setUser: (user) => set(() => ({ user })),
  setUserCheckComplete: (isUserCheckComplete) => set(() => ({ isUserCheckComplete })),
});
